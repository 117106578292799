import { FormattedMessage, injectIntl } from "gatsby-plugin-intl";
import React from "react";
import Helmet from "react-helmet";
import Container from "../components/Container";
import Form from "../components/Form";
import {
  loader,
  target,
  waveHorizontalLarge,
  wikiIllustration
} from "../components/Images";
import Layout from "../components/Layout";
import Row from "../components/Row";
import styled from "../styling/styled";
import { theme } from "../styling/theme";

const Content = styled.div`
  padding-top: 80px;
  padding-bottom: 60px;
  ${[theme.media.maxM]} {
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

const ContentHeader = styled(Content)`
  padding-top: 120px;
  padding-bottom: 80px;
  background-size: 160px 17px, 28px 28px, 28px 28px;
  background-image: url(${waveHorizontalLarge}), url(${target}), url(${loader});
  background-repeat: no-repeat;
  background-position: left calc(${theme.container.background} - 277px) top 90px,
    right calc(${theme.container.background} - 120px) top 50px,
    left calc(${theme.container.background} - 77px) bottom 90px;

  ${[theme.media.mobileMenuVisible]} {
    padding-top: 75px;
    padding-bottom: 30px;
    min-height: 0;
  }
`;

const ContentForm = styled(Content)`
  background-size: 160px 17px;
  background-image: url(${waveHorizontalLarge});
  background-repeat: no-repeat;
  background-position: right calc(${theme.container.background} - 277px) top
    90px;
`;

const RowWiki = styled(Row)`
  align-items: flex-start;
  position: relative;
  ${[theme.media.maxM]} {
    display: block;
  }
`;

const Col6 = styled.div`
  max-width: 50%;
  flex-basis: 50%;
  ${[theme.media.maxM]} {
    max-width: 100%;
    flex-basis: 100%;
  }
`;

const Illustration = styled.img`
  margin-top: -100px;
  margin-left: auto;
  display: block;
  pointer-events: none;
  ${[theme.media.maxM]} {
    margin-top: 0;
    margin-left: 0;
  }
`;

class WikiPage extends React.Component {
  render() {
    const intl = this.props.intl;
    return (
      <>
        <Helmet
          title={
            intl.formatMessage({ id: "pages.wiki.title1" }) + " | Eledio.com"
          }
          meta={[
            {
              name: "description",
              content: intl.formatMessage({ id: "pages.wiki.text1" })
            },
            {
              property: "og:title",
              content: intl.formatMessage({ id: "pages.wiki.title1" })
            },
            {
              property: "og:description",
              content: intl.formatMessage({ id: "pages.wiki.text1" })
            }
          ]}
        >
          <html lang={intl.locale} />
        </Helmet>
        <Layout>
          <ContentHeader>
            <Container>
              <h1>
                <FormattedMessage id="pages.wiki.title2" />
              </h1>
              <h3>
                <FormattedMessage id="pages.wiki.title3" />
              </h3>
              <Illustration src={wikiIllustration} alt="Wiki illustration" />
            </Container>
          </ContentHeader>
          <ContentForm>
            <Container>
              <RowWiki spacing={15}>
                <Col6>
                  <h3>
                    <FormattedMessage id="pages.wiki.title4" />
                  </h3>
                  <p>
                    <FormattedMessage id="pages.wiki.text1" />
                  </p>
                </Col6>
                <Col6>
                  <Form secondary />
                </Col6>
              </RowWiki>
            </Container>
          </ContentForm>
        </Layout>
      </>
    );
  }
}

export default injectIntl(WikiPage);
