import { ErrorMessage, Field } from "formik";
import { Link } from "gatsby-plugin-intl";
import styled from "../../styling/styled";
import { theme } from "../../styling/theme";

interface CheckboxProps {
  name: string;
  label: string;
  secondary?: string;
  link: string;
  linkText: string;
}

const CheckBox = styled.label<CheckboxProps>`
  margin-right: 15px;
  padding-left: 37px;
  font-size: 12px;
  display: block;
  position: relative;

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }

  input[type="checkbox"]:focus + .fake-input {
    box-shadow: 0 0 2px 0 ${theme.colors.blue};
  }

  input[type="checkbox"]:checked + .fake-input::after {
    background-color: ${theme.colors.blue};
  }

  .fake-input {
    width: 20px;
    height: 20px;
    background-color: ${props =>
      props.secondary ? theme.colors.blueLight : theme.colors.white};
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    transition: box-shadow 0.1s ease-in-out;
    &::before,
    &::after {
      display: block;
      position: absolute;
      content: "";
      background-color: ${props =>
        props.secondary ? theme.colors.blueLight : theme.colors.white};
      transition: all 0.1s ease-in-out;
    }

    &::before {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    &::after {
      left: 3px;
      right: 3px;
      top: 3px;
      bottom: 3px;
    }
  }
`;

const Error = styled(ErrorMessage)`
  font-size: 12px;
  padding-left: 37px;
  color: ${theme.colors.red};
`;

const CheckboxComponent = (props: CheckboxProps) => (
  <div>
    <CheckBox {...props}>
      <Field name={props.name} component="input" type="checkbox" />
      <span className="fake-input" />
      <span>
        {props.label}
        <Link to={props.link}>{props.linkText}</Link>
      </span>
    </CheckBox>
    <Error name="agreement" component="div" />
  </div>
);

export default CheckboxComponent;
