import { ErrorMessage, Field } from "formik";
import React from "react";
import styled from "../../styling/styled";
import { theme } from "../../styling/theme";

interface InputProps {
  name: string;
  type: string;
  secondary?: string;
  placeholder?: string;
}

const StyledInput = styled(Field)<InputProps>`
  width: 100%;
  margin-bottom: 10px;
  padding: 10px 15px;
  background-color: ${props =>
    props.secondary ? theme.colors.blueLight : theme.colors.white};
  border: 0;
  font-size: 15px;
  color: ${theme.colors.grayBlue};
  &:focus {
    box-shadow: 0 0 2px 0 ${theme.colors.blue};
  }
  &::placeholder {
    color: ${theme.colors.grayBlue};
  }
`;

const Error = styled(ErrorMessage)`
  margin-top: -5px;
  margin-bottom: 10px;
  font-size: 12px;
  padding-left: 15px;
  color: ${theme.colors.red};
`;

const InputComponent = (props: InputProps) => (
  <>
    <StyledInput {...props} />
    <Error name={props.name} component="div" />
  </>
);

export default InputComponent;
