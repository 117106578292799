import { Form, Formik } from "formik";
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl";
import React from "react";
import * as Yup from "yup";
import styled from "../styling/styled";
import { theme } from "../styling/theme";
import Checkbox from "./Form/Checkbox";
import InputText from "./Form/InputText";
import Textarea from "./Form/Textarea";

interface Props {
  secondary?: string;
}

interface FormSchemaProps {
  intl: object;
}

const getMessageFormSchema = (intl: FormSchemaProps) => {
  return Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: "components.form.errorEmailInvalid" }))
      .required(intl.formatMessage({ id: "components.form.errorEmail" })),
    message: Yup.string()
      .max(1000, intl.formatMessage({ id: "components.form.errorMessageLong" }))
      .required(intl.formatMessage({ id: "components.form.errorMessage" })),
    agreement: Yup.bool().test(
      "agreement",
      intl.formatMessage({ id: "components.form.errorCheckbox" }),
      value => value === true
    )
  });
};

const FormStyled = styled(Form)`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  ${[theme.media.maxMD]} {
    margin-top: 40px;
  }
`;

const SuccessMessage = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: ${props =>
    props.secondary ? theme.colors.white : theme.colors.blueLight};
`;

const StatusMessage = styled.div`
  width: 100%;
  margin-top: 5px;
  text-align: right;
`;

const ErrorMessage = styled.span`
  color: ${theme.colors.red};
`;

const FormFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Button = styled.button`
  padding: 10px 13px;
  color: ${theme.colors.white};
  background-color: ${theme.colors.red};
  transition: all 0.3s ease-in-out;
  font-weight: 700;
  font-family: ${theme.font.proxima};
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 15px;
  border: 0;
  &:hover {
    background-color: ${theme.colors.redHover};
  }
`;

class MessageForm extends React.Component<Props> {
  state = {
    formSending: false,
    formSentError: false,
    formSentSuccess: false
  };

  render() {
    const { secondary } = this.props;
    const intl = this.props.intl;

    return (
      <Formik
        initialValues={{ email: "", message: "", agreement: false }}
        validationSchema={getMessageFormSchema(intl)}
        onSubmit={(values, actions) => {
          this.setState({ formSending: true });
          const url =
            "https://cahja4jybd.execute-api.eu-central-1.amazonaws.com/default/contactForm";

          fetch(url, {
            method: "POST",
            body: JSON.stringify({
              customer: values.email,
              message: values.message
            })
          })
            .then(() => {
              this.setState({
                formSentSuccess: true,
                formSentError: false,
                formSending: false
              });
              actions.resetForm();
            })
            .catch(error => {
              this.setState({
                formSentSuccess: false,
                formSentError: true,
                formSending: false
              });
            });
        }}
        render={({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting
        }) => (
          <FormStyled noValidate>
            <div>
              {this.state.formSentSuccess && (
                <SuccessMessage>
                  <FormattedMessage id="components.form.responseSuccess" />
                </SuccessMessage>
              )}
            </div>

            <InputText
              type="email"
              name="email"
              secondary={secondary}
              placeholder={intl.formatMessage({ id: "components.form.email" })}
            />
            <Textarea
              name="message"
              secondary={secondary}
              placeholder={intl.formatMessage({
                id: "components.form.message"
              })}
              rows={4}
            />
            <FormFooter>
              <Checkbox
                name="agreement"
                label={intl.formatMessage({
                  id: "components.form.checkbox"
                })}
                secondary={secondary}
                link="/terms-and-conditions/"
                linkText={intl.formatMessage({
                  id: "components.form.link"
                })}
              />

              <Button type="submit">
                <FormattedMessage id="components.form.button" />
              </Button>
            </FormFooter>
            <StatusMessage>
              {this.state.formSentError}
              {this.state.formSentError && (
                <ErrorMessage>
                  <FormattedMessage id="components.form.responseError" />
                </ErrorMessage>
              )}
              {this.state.formSending &&
                intl.formatMessage({
                  id: "components.form.responseSending"
                })}
            </StatusMessage>
          </FormStyled>
        )}
      />
    );
  }
}

export default injectIntl(MessageForm);
